/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { useI18next } from "gatsby-plugin-react-i18next"
import { Helmet } from "react-helmet"
export default function Seo({
  description,
  title,
  keywords,
  image,
  url,
  alternativeUrls,
  children,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  )

  const { i18n } = useI18next()

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: i18n.language,
        }}
      >
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={new URL(image, site.siteMetadata?.siteUrl).toString()}
        />
        <meta property="og:url" content={url ?? site.siteMetadata?.siteUrl} />
        <meta property="og:site_name" content="Shipper Reviews" />
        <meta property="og:locale" content={i18n.language} />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:creator"
          content={site.siteMetadata?.author || ``}
        />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        {/* <meta property="fb:app_id" content={site.siteMetadata?.facebookAppID} /> */}
        {alternativeUrls?.map(({ url, lang }, i) => {
          return <link key={i} rel="alternate" hrefLang={lang} href={url} />
        })}
        {children}
      </Helmet>
    </>
  )
}

Seo.defaultProps = {
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string.isRequired,
}
